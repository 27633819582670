import React, { useState, useEffect } from 'react';
import { NavigationContext, assignFocus, useNavigation, getRootNode } from '@px/react-lrud';
import NavigableList from '../NavigableList/NavigableList.js';
import NavigableButton from '../NavigableButton/NavigableButton.js';
import NullNavigationNode from '../NullNavigationNode/NullNavigationNode.js';
import SurveyIntro from '../SurveyIntro/SurveyIntro.js';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion.js';
const SurveyOutro = SurveyIntro; // TODO may want a separate component for this;
import './Survey.scss';

const Survey = ({
  config,
  userId,
  episodeTitle,
  episodeImage,
  episodePid,
  onSubmitResponses,
  onClose,
}) => {
  const { width: imageWidth, height: imageHeight } = episodeImage;
  const imageSrc = episodeImage?.unattributed_url ?? episodeImage.url;
  const [currentPage, setCurrentPage] = useState(0);
  const [closed, setClosed] = useState(false);
  const [currentQuestionResponse, setCurrentQuestionResponse] = useState(null);
  const [storedResponses, setStoredResponses] = useState([]);

  const surveySkippable = config.skippable;
  const pages = [];

  if (config.introPage) {
    pages.push({ type: 'intro', page: config.introPage });
  }

  config.questions.forEach((question) => {
    pages.push({ type: 'question', page: question });
  });

  if (config.outroPage) {
    pages.push({ type: 'outro', page: config.outroPage });
  }

  const { type: pageType, page: pageConfig } = pages[currentPage] ?? {};
  const isIntro = pageType === 'intro';
  const isOutro = pageType === 'outro';
  const isQuestion = pageType === 'question';

  const handleCloseSurvey = () => {
    setClosed(true); // To update the view shown by this component
    if (onClose) {
      onClose(); // To wait for outstanding submission and close the survey
    }
  };

  const handleSkipSurvey = () => {
    setCurrentPage(pages.length);
  };

  const handleQuestionSelect = (response) => {
    setCurrentQuestionResponse(response);
  };

  const handleQuestionSubmit = (response) => {
    if (isQuestion) {
      setStoredResponses([
        ...storedResponses,
        response,
      ]);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleQuestionSkip = () => {
    handleQuestionSubmit(null);
  };

  useEffect(() => {
    if (storedResponses.length === config.questions.length) {
      onSubmitResponses(storedResponses);
    }
  }, [storedResponses]);

  useEffect(() => {
    if (currentPage >= pages.length) {
      handleCloseSurvey();
    }

    // assign focus to default action for intro and outro; questions must assign their own focus to the default option.
    if (isIntro) {
      assignFocus('intro_continue');
    } else if (isOutro) {
      assignFocus('outro_close');
    } 

    // Reset the current cached response to the current question to the empty list,
    // which is needed for the 'multiple' type questions, which can be submitted without selecting any option
    setCurrentQuestionResponse([]);
  }, [pages.length, currentPage, isIntro, isOutro]);

  const {
    ref,
    actualId,
  } = useNavigation({
    isFocusable: false,
    orientation: 'vertical',
    id: 'survey',
  });

  return (
    <div className="survey" ref={ref}>
      <NavigationContext.Provider value={actualId}>
        <img className="survey-background-image" src={imageSrc} width={imageWidth} height={imageHeight} />

        <div className="survey-content">
          <div className="survey-header">
            <h1>PX Survey</h1>
            {isQuestion && (
              <h2>Question {currentPage + (config.introPage ? 0 : 1)} of {config.questions.length}</h2>
            )}
            {isIntro && (
              <h2>Introduction</h2>
            )}
            {isOutro && (
              <h2>Thank you</h2>
            )}
          </div>

          <div className="survey-main">
            <NavigableList orientation="vertical" id="survey_main" grow>
              {isIntro && (
                <SurveyIntro config={pageConfig} episodeTitle={episodeTitle} />
              )}
              {isQuestion && (
                <SurveyQuestion
                  config={pageConfig}
                  onSelect={handleQuestionSelect}
                  onSubmit={handleQuestionSubmit}
                />
              )}
              {isOutro && (
                <SurveyOutro config={pageConfig} />
              )}
            </NavigableList>
          </div>

          <div className="survey-footer">
            <NavigableList orientation="horizontal" id="survey_footer" resetOnChildrenChange>
              {isIntro
              ? (
                  <NavigableButton
                    primary
                    content="Continue"
                    onSelect={() => setCurrentPage(1)}
                    id="intro_continue"
                    index={0}
                  />
              )
              : <NullNavigationNode index={0} />}
              {isIntro && surveySkippable
              ? (
                    <NavigableButton content="Skip survey" onSelect={handleSkipSurvey} id="intro_skip" index={1} />
              )
              : <NullNavigationNode index={1} />}
              {isQuestion && pageConfig.type === 'multiple'
              ? (
                <NavigableButton
                  primary
                  content="Save &amp; continue"
                  onSelect={() => handleQuestionSubmit(currentQuestionResponse)}
                  id="question_submit"
                  index={2}
                />
              )
              : <NullNavigationNode index={2} />}
              {isQuestion && pageConfig.skippable
              ? (
                <NavigableButton
                  content="Skip question"
                  onSelect={() => handleQuestionSkip()}
                  id="question_skip"
                  index={3}
                />
              )
              : <NullNavigationNode index={3} />}
              {isOutro
              ? (
                  <NavigableButton
                    primary
                    content="Close"
                    onSelect={handleCloseSurvey}
                    id="outro_close"
                    index={4}
                  />
              )
              : <NullNavigationNode index={4} />}
            </NavigableList>
          </div>
        </div>
      </NavigationContext.Provider>
    </div>
  );
}
export default Survey;
