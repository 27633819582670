import React, { useState, useEffect } from 'react';
import { useNavigation, NavigationContext } from '@px/react-lrud';
import NavigableList from '../NavigableList/NavigableList.js';
import NavigableButton from '../NavigableButton/NavigableButton.js';
import NullNavigationNode from '../NullNavigationNode/NullNavigationNode.js';
import SingleOptions from './SingleOptions.js';
import StarsOptions from './StarsOptions.js';
import MultipleOptions from './MultipleOptions.js';
import LikertOptions from './LikertOptions.js';
import Icon from '../Icon/Icon.js';
import './SurveyQuestion.scss';

const SurveyIntro = ({
  config,
  onSelect,
  onSubmit,
}) => {
  const {
    id,
    title,
    type,
    options,
  } = config;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeOption, setActiveOption] = useState();

  useEffect(() => {
    setActiveOption(options[0]?.value)
    setSelectedOptions([]);
  }, [id, options]);

  const handleSelect = (value) => {
    if (type === 'multiple') {
      let newSelectedOptions;
      if (selectedOptions.includes(value)) {
        newSelectedOptions = selectedOptions.filter(d => d !== value);
      } else {
        newSelectedOptions = [...selectedOptions, value];
      }
      setSelectedOptions(newSelectedOptions);
      onSelect && onSelect(newSelectedOptions);
    } else {
      onSubmit && onSubmit(value);
    }
  };

  const handleActive = (value) => {
    setActiveOption(value);
  };

  const {
    ref,
    actualId,
    focused
  } = useNavigation({
    isFocusable: false,
    id: `question_${id}`,
  });

  const optionsProps = {
    options,
    handleSelect,
    handleActive,
    selectedOptions,
    activeOption,
  };

  return (
    <div className="survey-question" ref={ref}>
      <NavigationContext.Provider value={actualId}>
        <h1>{title}</h1>

        <div className="survey-question-options">
          {type === 'stars' ? <StarsOptions {...optionsProps} /> : <NullNavigationNode index={0} />}
          {type === 'single' ? <SingleOptions {...optionsProps} /> : <NullNavigationNode index={1} />}
          {type === 'likert' ? <LikertOptions {...optionsProps} /> : <NullNavigationNode index={2} />}
          {type === 'multiple' ? <MultipleOptions {...optionsProps} /> : <NullNavigationNode index={3} />}
        </div>
      </NavigationContext.Provider>
    </div>
  );
};

export default SurveyIntro;
