import TokenManager, {
  tokenSetToHeader,
} from '@px/px-system-apis/src/interfaces/auth/token-manager.js';

const {
  catalogueHost,
  surveyUrl,
  mockEpisodeData,
  pxApplicationAuth,
} = appConfig;

const catalogueServiceUrl = `https://${catalogueHost}/api`;

const tokenManager = new TokenManager(pxApplicationAuth);

const authenticatedFetch = async (path, options) => {
  let authHeaders;
  try {
    // TODO getDeviceTokenSet() hangs instead of throwing when no auth token is available.
    // As per docs it should return a promise that rejects, but it seems to just wait forever for a token to become available
    // https://confluence.dev.bbc.co.uk/display/PLATFORMEX/PX+Auth+API#PXAuthAPI-TokenManager/
    authHeaders = {
      'X-PX-Application-Auth': tokenSetToHeader(
        await tokenManager.getApplicationTokenSet(),
      ),
      'X-PX-Device-Auth': tokenSetToHeader(
        await tokenManager.getDeviceTokenSet(),
      ),
    };
  } catch (e) {
    console.error('Could not get authentication tokens for fetch, sending request without them.', e);
    authHeaders = {};
  }

    return fetch(path, {
      ...options,
      headers: {
        ...options?.headers ?? {},
        ...authHeaders,
      },
    });
}

export const fetchEpisodeData = async (catalogue, pid) => {
  if (!catalogueHost) {
    if (mockEpisodeData) {
      console.warn('using mockEpisodeData because no catalogueHost is defined in config.json.');
      return mockEpisodeData;
    } else {
      throw new Error('neither catalogueHost nor mockEpisodeData defined in config.json.');
    }
  }

  const response = await authenticatedFetch(`${catalogueServiceUrl}/catalogues/${catalogue}/episodes/${pid}`);

  if (!response.ok) {
    throw new Error('Failed to load episode information from catalogue.');
  }

  const episode = await response.json();

  // console.log('received episode information from catalogue', episode);

  const episodeInfo = {
    fullTitle: episode?.fullTitle,
    images: episode?.images,
    pxAnnotation: {
      afterViewingSurvey: episode?.pxAnnotation?.afterViewingSurvey,
    }
  };

  return episodeInfo;
};

export const submitResponses = async (userInfo, pid, responses) => {
  const body = JSON.stringify({
    userSub: userInfo.sub,
    pid,
    responses,
  });

  if (!surveyUrl) {
    console.log('no surveyUrl defined in config.json; not submitting response.', body);
    return;
  }

  // timestamp is added and the response formatted as CSV for storage on the server
  const res = await authenticatedFetch(surveyUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  if (res.ok) {
    console.log('submitted responses.');
  } else {
    console.log('failed to submit responses: ' + res.status);
  }
};
