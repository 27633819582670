import React from 'react';
import { useNavigation } from '@px/react-lrud';

const NullNavigationNode = ({ index }) => {
  useNavigation({
    isFocusable: false,
    index,
  });

  return null;
}

export default NullNavigationNode;
