import React from 'react';
import './Icon.scss';

// https://www.bbc.co.uk/gel/features/iconography
// https://github.com/bbc/gel-iconography/tree/main/dist

const icons = {
  'star-filled': <path d="m16 25.1-9.9 6.1 2.8-11.3L0 12.4l11.6-.9L16 .8l4.4 10.8 11.6.8-8.9 7.5 2.8 11.3-9.9-6.1z"></path>,
  'star': <path d="M18.6 14 16 7l-2.6 7-7.5.3 5.9 4.6-2 7.3L16 22l6.3 4.2-2-7.3 5.9-4.6-7.6-.3zM16 25.1l-9.9 6.1 2.8-11.3L0 12.4l11.6-.9L16 .8l4.4 10.8 11.6.8-8.9 7.5 2.8 11.3-9.9-6.1z"></path>,
  'confirm': <path d="m32 7.2-2.5-2.4L11 23.3h2L2.4 12.6 0 15.1l12 12.1 20-20z"/>,
}

const Icon = ({
  name,
}) => {
  const icon = icons[name];

  if (!icon) {
    return null;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="icon">
      {icon}
    </svg>
  )
}
export default Icon;
