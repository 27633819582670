import React, { useEffect } from 'react';
import { assignFocus } from '@px/react-lrud';
import NavigableList from '../NavigableList/NavigableList.js';
import NavigableButton from '../NavigableButton/NavigableButton.js';
import Icon from '../Icon/Icon.js';

const StarsOptions = ({
    options,
    handleSelect,
    handleActive,
    selectedOptions,
    activeOption,
}) => {
  useEffect(() => {
    assignFocus('stars_option_0');
  }, [options]);

  return (
    <>
      <NavigableList orientation="horizontal" id={`stars_options`}>
        {options.map(({ value, label }, i) => (
          <NavigableButton
            primary
            key={`stars_option_${i}`}
            id={`stars_option_${i}`}
            content={`${label}`}
            onSelect={() => handleSelect(value)}
            onActive={() => handleActive(value)}
            selected={selectedOptions.includes(value)}
          />
        ))}
      </NavigableList>
      <div className="survey-questions-stars" style={{ fontSize: '2rem', display: 'flex', gap: '0.5rem', margin: '1rem 0' }}>
        {[1, 2, 3, 4, 5].map((value) => (
          <Icon
            key={value}
            name={parseInt(value) <= parseInt(activeOption) ? 'star-filled' : 'star'}
          />
        ))}
      </div>
    </>
  );
};

export default StarsOptions;
        
