import '../Survey/Survey.scss';
import './FallbackMessage.scss';
import React from 'react';
import classnames from 'classnames';

const FallbackMessage = ({
  error,
}) => {
  const isError = !!error;
  return (
    <div className="survey">
      <div className="survey-content">
        <div className={classnames({ 'fallback-message-delayed-reveal': !isError })}>
          <h1>{ isError ? 'Something went wrong' : 'This is taking longer than expected' }</h1>
          <p>Press the HOME button on your remote to close this page.</p>
          { isError && (
          <>
            <hr />
            <p>{error}</p>
          </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FallbackMessage;
