import './index.scss';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import NavigableList from './components/NavigableList/NavigableList.js';
import NavigableButton from './components/NavigableButton/NavigableButton.js';
import Survey from './components/Survey/Survey.js';
import FallbackMessage from './components/FallbackMessage/FallbackMessage.js';
import { initNavigation, useNavigation, NavigationContext, assignFocus } from '@px/react-lrud';
import PXAuthAPI from '@px/px-system-apis/src/interfaces/auth/index.js';
import { fetchEpisodeData, submitResponses } from './util.js';
import ApplicationInterface from '@px/px-system-apis/src/interfaces/application/index.js';

const application = new ApplicationInterface();
const pxAuthAPI = new PXAuthAPI();


initNavigation({ debug: false });

const App = function () {
  const id = 'root';

  const { actualId } = useNavigation({
    id,
    isFocusable: false,
  });

  const [surveyConfig, setSurveyConfig] = useState(null);
  const [episodeData, setEpisodeData] = useState(null);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pid, setPid] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [waitingForSubmit, setWaitingForSubmit] = useState(false);
  const [closeRequested, setCloseRequested] = useState(false);

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const catalogue = searchParams.get('catalogue');
      const pid = searchParams.get('pid');
      setPid(pid);

      try {
        if (!pid || !catalogue) {
          throw new Error('URL must include pid and catalogue parameters to fetch the survey configuration.');
        }

        const episodeData = await fetchEpisodeData(catalogue, pid);
        setEpisodeData(episodeData);

        const surveyConfig = episodeData?.pxAnnotation?.afterViewingSurvey;
        setSurveyConfig(surveyConfig);

        if (!episodeData || !surveyConfig) {
          throw new Error(`No survey configuration found for ${pid} in ${catalogue} catalogue.`);
        }

        setUserInfo(await pxAuthAPI.fetchUserInfo());
      } catch (e) {
        console.error(e);
        setError(`${e}`);
      }
    })();
  }, []);

  useEffect(() => console.log(userInfo), [userInfo]);

  const handleSubmitResponses = async (responses) => {
    // TODO no lock, means multiple submissions could be triggered in parallel
    setWaitingForSubmit(true);
    await submitResponses(userInfo, pid, responses)
    setWaitingForSubmit(false);
  };

  useEffect(() => {
    if (closeRequested && !waitingForSubmit) {
      console.log('requesting app close');
      try {
        application.close();
      } catch (e) {
        console.error('failed to close app', e);
      }
    }
  }, [closeRequested, waitingForSubmit]);


  if (error || !surveyConfig || closeRequested) {
    return (
      <FallbackMessage error={error} />
    );
  }

  return (
    <div className="app">
      <NavigationContext.Provider value={actualId}>
        <Survey
          config={surveyConfig}
          episodeTitle={episodeData?.fullTitle}
          episodeImage={episodeData?.images[0]}
          episodePid={episodeData?.pid}
          onSubmitResponses={handleSubmitResponses}
          onClose={() => setCloseRequested(true)}
        />
      </NavigationContext.Provider>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('content'));
