import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useNavigation, NavigationContext } from '@px/react-lrud';

import './NavigableList.scss';

const NavigableList = ({
  children,
  orientation = 'vertical',
  id,
  onActive,
  onInactive,
  resetOnChildrenChange,
  grow,
}) => {
  const {
    ref,
    actualId,
    hasFocusWithin,
    active,
    getNode,
    setActiveChild,
  } = useNavigation({
    isFocusable: false,
    orientation,
    id,
    onActive,
    onInactive,
  });

  useEffect(() => {
    if (resetOnChildrenChange) {
      setActiveChild(getNode()?.children?.find(c => c.isFocusable))
    }
  }, [resetOnChildrenChange, children])

  const focused = hasFocusWithin && active;

  return (
    <div className={classNames('navigable-list', orientation, { focused, grow })} ref={ref}>
      <NavigationContext.Provider value={actualId}>
        {children}
      </NavigationContext.Provider>
    </div>
  );
};

export default NavigableList;
