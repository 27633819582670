import React from 'react';
import classNames from 'classnames';
import { useNavigation } from '@px/react-lrud';
import Icon from '../Icon/Icon.js';
import './NavigableButton.scss';

const NavigableButton = ({
  content,
  id,
  onSelect,
  primary = false,
  selectable = false,
  selected,
  index,
  onActive,
}) => {
  const {
    active,
    focused,
    ref,
    actualId,
  } = useNavigation({
    id,
    index, // TODO look up what index actually does because it is not working as expected to maintain nav order
    onSelect: () => {
      if (onSelect) {
        onSelect();
      }
    },
    onActive,
  });

  const activeAndFocused = active && focused

  // TODO gel icons for selected/not selected?
  // <span className={classNames('navigable-button-selectable-icon', { selected })}/>
  return (
    <button className={classNames('navigable-button', { focused: activeAndFocused, primary, selectable, selected })} ref={ref}>
      {selectable && (
        <span className={classNames('navigable-button-selectable-icon', { selected })}>
          <Icon name="confirm" />
        </span>
      )}
      {content}
    </button>
  );
};

export default NavigableButton;
