import React, { useEffect } from 'react';
import { assignFocus } from '@px/react-lrud';
import NavigableList from '../NavigableList/NavigableList.js';
import NavigableButton from '../NavigableButton/NavigableButton.js';

const MultipleOptions = ({
    options,
    handleSelect,
    handleActive,
    selectedOptions,
}) => {
  useEffect(() => {
    assignFocus(`multiple_option_0`);
  }, [options]);

  return (
    <NavigableList orientation="vertical" id={`multiple_options`}>
      {options.map(({ value, label }, i) => (
        <NavigableButton
          primary
          key={`multiple_option_${i}`}
          id={`multiple_option_${i}`}
          content={`${label}`}
          onSelect={() => handleSelect(value)}
          onActive={() => handleActive(value)}
          selected={selectedOptions.includes(value)}
          selectable
        />
      ))}
    </NavigableList>
  );
};

export default MultipleOptions;
