import React, { useEffect } from 'react';
import { assignFocus } from '@px/react-lrud';
import NavigableList from '../NavigableList/NavigableList.js';
import NavigableButton from '../NavigableButton/NavigableButton.js';

const LikertOptions = ({
    options,
    handleSelect,
    handleActive,
    selectedOptions,
}) => {
  useEffect(() => {
    assignFocus(`likert_option_${Math.floor(options.length / 2)}`);
  }, [options]);

  // TODO set default selection to middle item or one specified in metadata
  return (
    <NavigableList orientation="horizontal" id={`likert_options`}>
      {options.map(({ value, label }, i) => (
        <NavigableButton
          primary
          key={`likert_option_${i}`}
          id={`likert_option_${i}`}
          content={`${label}`}
          onSelect={() => handleSelect(value)}
          onActive={() => handleActive(value)}
          selected={selectedOptions.includes(value)}
        />
      ))}
    </NavigableList>
  );
};

export default LikertOptions;
